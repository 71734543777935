import React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import LandingPageForm from "../Common/LandingPageForm"


const LagerverwaltungssoftwareIntro = () => {
  return (
    <section className="about-area bg-fafafb pt-4 pb-4 px-5" style={{ marginTop: "6%" }}>
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col lg={8} md={12}>
            <div className="about2-content">
              <div className="content">
                <h1>Lagerverwaltungssoftware im Überblick</h1>
                <p className={"border-left pl-3 border-primary"}>
                  Das Manamgement eines Lagers ist essentieller Bestandteil von Retailern und Großhändlern. LOGENTIS hat
                  in verschiedenen Projekten unter Beweis gestellt, das Läger mit Software aus unserem Haus besser
                  operiert werden können. <Link to={"/lagerverwaltung/"}>NETSTORSYS</Link> ist die logische Entwicklung
                  unserer Erfahrungen in individuellen Softwareprojekten im Bereich Warehouse Management Software.
                </p>

                <h2 className={"h4 text-primary"}>Funktionsvielfalt des WMS</h2>
                <p>
                  Neben den klassischen Funktionen einer Lagerverwaltungssoftware wie Ein-, Um- und Auslagerung, bzw.
                  Kommissionierung müssen moderne Systeme im Retail weiterreichende Anforderungen erfüllen. Neben einer
                  effizienten Retoureverwaltung, die besonders im E-Commerce und dem 14-tägigen Rückgaberecht eine
                  wichtige Rolle einnimmt, sind auch Bereiche wie Stammdatenmanagement, Inventuren oder Fotoshooting
                  integraler Bestandteil vieler Händler. Ein modernes Realtime Dashboard soll zudem die Managebarkeit
                  der Logistik vereinfachen.
                </p>

                <h2 className={"h4  text-primary"}>Integration in Vorsysteme</h2>
                <p>
                  Lagerlogistik ist ein integraler Bestandteil der Supply-Chain und erfordert somit eine nahtlose
                  Integration in Vorsysteme wie Enterprise Resource Planing (ERP) oder Shopsysteme. Die Schnittstellen
                  in die Systeme sind dabei zumeist bidirektional, denn neben Auftragsdaten, die in das WMS gelangen,
                  sind auch Artikeldaten ein entscheidener Faktor für die Berechung von Lagerstandorten.
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={12}>
            <LandingPageForm/>
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default LagerverwaltungssoftwareIntro
