import React from "react"
import { Alert, Col, Row } from "react-bootstrap"
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import { useContactForm } from "../Contact/ContactForm"


const LandingPageForm = () => {
  const { error, errors, handleSubmit, isErrors, onSubmit, register, setReToken, submitted } = useContactForm()

  return (
    <div className="jumbotron">
      <h2 className="display-5"><i className="bx bx-bolt-circle" /> Direktkontakt</h2>
      <p className="lead">Sie wollen sofort eine Frage los werden?</p>
      <hr className="my-4" />
      <p>Einfach nur Namen und Telefonnummer eingeben und wir rufen pronto zurück!</p>

      {error &&
      <Row>
        <Col lg={12}>
          <Alert variant={"warning"}>
            Fehler beim Versenden. Bitte nochmal versuchen.
          </Alert>
        </Col>
      </Row>
      }

      <p>
        <form name="landingPageContactForm"
              action={"/success"}
              onSubmit={handleSubmit(onSubmit)}
              method={"POST"}
              encType={"application/x-www-form-urlencoded"}>
          <ReCaptcha
            action="homepage"
            siteKey="6LcK7jMcAAAAAOTkrsVN75ra4iaJCL8ZIXqb2Rl0"
            onVerify={(token) => setReToken(token)}
            badge={"bottomright"}
            submitted={!submitted}
          />

          <input type="hidden" name="form-name" value="landingPageContactForm"/>

          <Row className="form-group">
            <Col sm={12}>
              <div className={`form-group ${(isErrors && errors.FULLNAME) ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register('FULLNAME', {required: 'Vor- und Zuname notwendig'})}
                  className="form-control my-2"
                  style={{ width: "100%" }}
                  name={"FULLNAME"}
                  placeholder="Vor- und Zuname" />
                  {errors && errors.FULLNAME && <span className="error">{errors.FULLNAME.message}</span>}
              </div>
            </Col>
          </Row>
          <Row className="form-group">
            <Col sm={12}>
              <div className={`form-group ${(isErrors && errors.PHONE) ? 'has-error' : ''}`}>
                <input
                  type="text"
                  {...register('PHONE', {required: 'Telefonnummer notwendig'})}
                  className="form-control my-2"
                  name={"PHONE"}
                  placeholder="Telefonnummer" />
                <br/>
               {errors && errors.PHONE && <span className="error">{errors.PHONE.message}</span>}
              </div>
            </Col>
          </Row>

          <Row className="form-group mt-3">
            <Col sm={12}>
              <button type="submit" className="btn btn-secondary" disabled={submitted}>
                {submitted && "Wird abgeschickt...."}
                {!submitted && "und los!"}
              </button>
            </Col>
          </Row>

        </form>
      </p>
    </div>
  );
}

export default LandingPageForm
