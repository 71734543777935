import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import serviceIcon1 from "../../assets/images/lagerverwaltungssoftware/kommissionierung_icon.svg"
import serviceIcon2 from "../../assets/images/lagerverwaltungssoftware/hardwareneutral.svg"
import serviceIcon3 from "../../assets/images/lagerverwaltungssoftware/retoure_icon.svg"
import serviceIcon4 from "../../assets/images/lagerverwaltungssoftware/compass_icon.svg"


const FourFactors = () => {
  return (
    <section className="services-area pt-5 pb-70 bg-f1f8fb">
      <Container>
        <div className="section-title">
          <h2>4 Faktoren für eine erfolgreiche Lagerverwaltung</h2>
        </div>

        <Row>
          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon1} alt="features" style={{ width: "70px" }} />
              </div>
              <h3>
                Kommissionierung
              </h3>
              <p>
                Als einer der wichtigsten Prozesse innerhalb der Supply-Chain ist die Kommissionierung immer im Fokus.
                "Welche Aufträge werden an welchen Mitarbeiter übertragen?" und "Wie werden die kürzesten Wege
                erreicht", sind die Fragestellungen, die ein WMS lösen muss. Auch wenn die Kommissionierung zu einem
                gewissen Teil auf eine gute Einlagerungsstrategie beruht, ist es doch eine intelligente
                Kommissionierung, die eine gute Prozessgeschwindigkeit ausmachen.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon2} alt="features" style={{ width: "70px" }} />
              </div>
              <h3>
                Hardwareneutralität
              </h3>
              <p>
                Die Software sollte wenn möglich hardwareneutral sein. Es ist ein großer Vorteil Hardware wie mobile
                Computer oder Drucker, nach den spezifischen Anforderungen des Betriebs auszusuchen. Die Software sollte
                in der Lage sein mit vielen Konstellationen zu arbeiten. Das gleiche gilt natürlich auch für die Wahl
                des Servers, wenn die Lagerverwaltungssoftware serverbasiert ist.
              </p>
            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon3} alt="features" style={{ width: "70px" }} />
              </div>
              <h3>
                Retouremanagement
              </h3>

              <p>
                Die Rücknahme von Aufträgen, bzw. die Annahme von Retouren ist ein essentieller Prozess im
                Online-Retail. Bei Retourequoten von über 50% im Fashionbereich und ähnlichen Quoten in anderen
                Sortimenten, ist es wichtig, dass die Vereinnahmung von Retouren maximal schnell prozessiert werden.
                Besonders relevant ist die Strategie der Wiedereinlagerung bzw. eine direkte Kommissionierung bei
                Schnelldrehern. Denn der Grundsatz das Ware nicht unnötig oft bewegt werden sollte, gilt besonders bei
                Retouren.
              </p>

            </div>
          </Col>

          <Col sm={6} className={"p-3"}>
            <div className="single-services-item-box h-100">
              <div className="icon">
                <img src={serviceIcon4} alt="features" style={{ width: "70px" }} />
              </div>

              <h3>
                Echtzeitstatistiken
              </h3>

              <p>
                Die Bewertung von Mitarbeiterperformance oder die Optimierung von Lagerorten ist nur mit genauen Daten
                realisierbar. Echtzeitdaten helfen bei der kurzfristigen Personalplanung im Lager und ermöglichen
                digitale Visualisierungen via LED Displays, welche die Mitarbeitermotivation nachweislich fördern.
                Nutzen Sie die Möglichkeiten, die Ihnen eine gute Datenbasis liefert.
              </p>

            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FourFactors
