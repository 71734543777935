import React from "react"
import { Link } from "gatsby"
import {Container} from 'react-bootstrap'

const Footer = () => {

  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area" style={{marginTop: "0px", paddingTop: "0px"}}>
      <Container>
        <div className="footer-bottom-area" style={{marginTop: "0px", paddingTop: "20px"}}>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>Copyright @{currentYear} <strong>LOGENTIS GmbH</strong>. All rights reserved.
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/datenschutz/">
                    Datenschutz
                  </Link>
                </li>
                <li>
                  <Link to="/impressum/">
                    Impressum
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>

      {/*<div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>*/}
    </footer>
  )
}

export default Footer
