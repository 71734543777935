import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import FourFactors from "../components/Lagerverwaltungssoftware/FourFactors"
import LagerverwaltungssoftwareIntro from "../components/Lagerverwaltungssoftware/LagerverwaltungssoftwareIntro"
import Seo from "../components/App/Seo"
import FooterLanding from "../components/App/FooterLanding"

const AboutUs = () => {
  return (
    <Layout>
      <Seo title={"Lagerverwaltungssoftware im Überblick"}
           description={"Wichtige Features einer Lagerverwaltungssoftware für Sie zusammengefasst."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar showNavigation={false} showNetstorsysButton={true}/>
      <LagerverwaltungssoftwareIntro />
      <FourFactors />
      <FooterLanding/>
    </Layout>
  );
}


export default AboutUs;
